import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Agent, Supervisor, CustomEvent } from '../api/ai-gent';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { firstValueFrom, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    private _event$: BehaviorSubject<CustomEvent> =
        new BehaviorSubject<CustomEvent>({});
    // private rootUrl: string = 'http://localhost:9090';
    private rootUrl: string = 'https://api.aigentbackend.ngrok.app';

    constructor(private http: HttpClient) {}

    private createHeaders(): HttpHeaders {
        return new HttpHeaders({
            'ngrok-skip-browser-warning': 'true',
        });
    }

    private handleError(error: any): Observable<never> {
        console.error('Error:', error);
        return throwError(error);
    }

    getAgentDashboardData(): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/agent/Abel`, {
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    async getAgentsDetails(): Promise<Agent[]> {
        const response = await firstValueFrom(
            this.http.get<any>('assets/demo/data/agents.json')
        );
        return response.data as Agent[];
    }

    async getSupervisorsDetails(): Promise<Supervisor[]> {
        const response = await firstValueFrom(
            this.http.get<any>('assets/demo/data/supervisors.json')
        );
        return response.data as Supervisor[];
    }

    getEvent(): BehaviorSubject<CustomEvent> {
        return this._event$;
    }

    setEvent(event: CustomEvent): void {
        this._event$.next(event);
    }

    async getDynamicData(): Promise<any> {
        return firstValueFrom(this.http.get<any>('assets/demo/data/en.json'));
    }

    getTranscriptData(id: string | number): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/callTranscript/${id}`, {
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    async getSentimentSummaryData(): Promise<any> {
        return firstValueFrom(
            this.http.get<any>('assets/demo/data/sentimentSummary.json')
        );
    }

    getQualityData(id: any): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/callQuality/${id}`, {
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    getAllUsers(): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/users`, {
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    getCalls(): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/calls`, {
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    getCallsByNameDateCallId(): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/call`, { headers: this.createHeaders() })
            .pipe(catchError(this.handleError));
    }

    getCall(name: string, callDate: string, callId: number): Observable<any> {
        const params = new HttpParams()
            .set('name', name)
            .set('callDate', callDate)
            .set('callId', callId.toString());
        return this.http
            .get<any>(`${this.rootUrl}/call`, {
                params,
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    getAllAgent(): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/user/?role=Agent`, {
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    getAllSupervisor(): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/user/?role=Supervisor`, {
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }
    getUserCountByRole(): Observable<any> {
        return this.http.get<any>(
            `${this.rootUrl}/user/userCount/?role=Supervisor`
        );
    }
    getCallsFeedbackDetailsList(params: any): Observable<any> {
        let httpParams = new HttpParams();
        for (const key in params) {
            if (
                params.hasOwnProperty(key) &&
                params[key] !== null &&
                params[key] !== undefined &&
                params[key] !== false
            ) {
                httpParams = httpParams.append(key, params[key]);
            }
        }
        return this.http
            .get<any>(`${this.rootUrl}/agent`, {
                params: httpParams,
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    getManagerDashboard(): Observable<any> {
        return this.http
            .get<any>(`${this.rootUrl}/manager?isWeekly=true`, {
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    getManagerCallsFeedbackDetailsList(params: any): Observable<any> {
        let httpParams = new HttpParams();
        for (const key in params) {
            if (
                params.hasOwnProperty(key) &&
                params[key] !== null &&
                params[key] !== undefined &&
                params[key] !== false
            ) {
                httpParams = httpParams.append(key, params[key]);
            }
        }
        return this.http
            .get<any>(`${this.rootUrl}/manager`, {
                params: httpParams,
                headers: this.createHeaders(),
            })
            .pipe(catchError(this.handleError));
    }
    updateAgent(id: number, user: any): Observable<any> {
        return this.http.patch<any>(`${this.rootUrl}/user/${id}`, user);
    }
    deleteAgent(id: number): Observable<any> {
        return this.http.delete<any>(`${this.rootUrl}/user/${id}`);
    }
}
