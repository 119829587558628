import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from './layout/app.layout.component';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: AppLayoutComponent,
                    children: [
                        // {
                        //     path: '',
                        //     loadChildren: () =>
                        //         import(
                        //             './demo/components/auth/auth.module'
                        //         ).then((m) => m.AuthModule),
                        // },
                        {
                            path: '',
                            loadChildren: () =>
                                import(
                                    './demo/components/dashboard/dashboard.module'
                                ).then((m) => m.DashboardModule),
                        },
                        // {
                        //     path: 'upload',
                        //     loadChildren: () =>
                        //         import(
                        //             './demo/components/upload-file/upload-file.module'
                        //         ).then((m) => m.UploadFileModule),
                        // },
                        {
                            path: 'home',
                            loadChildren: () =>
                                import(
                                    './demo/components/home/home.module'
                                ).then((m) => m.HomeModule),
                        },
                        {
                            path: 'agent-dashboard',
                            loadChildren: () =>
                                import(
                                    './demo/components/agent-dashboard/agent-dashboard.module'
                                ).then((m) => m.AgentDashboardModule),
                        },
                        {
                            path: 'manager-dashboard/managerDashboard',
                            loadChildren: () =>
                                import(
                                    './demo/components/manager-dashboard/manager-dashboard.module'
                                ).then((m) => m.ManagerDashboardModule),
                        },
                        {
                            path: 'uikit',
                            loadChildren: () =>
                                import(
                                    './demo/components/uikit/uikit.module'
                                ).then((m) => m.UIkitModule),
                        },
                        {
                            path: 'utilities',
                            loadChildren: () =>
                                import(
                                    './demo/components/utilities/utilities.module'
                                ).then((m) => m.UtilitiesModule),
                        },
                        {
                            path: 'documentation',
                            loadChildren: () =>
                                import(
                                    './demo/components/documentation/documentation.module'
                                ).then((m) => m.DocumentationModule),
                        },
                        {
                            path: 'blocks',
                            loadChildren: () =>
                                import(
                                    './demo/components/primeblocks/primeblocks.module'
                                ).then((m) => m.PrimeBlocksModule),
                        },
                        {
                            path: 'pages',
                            loadChildren: () =>
                                import(
                                    './demo/components/pages/pages.module'
                                ).then((m) => m.PagesModule),
                        },
                        {
                            path: 'call-dash-board/calldashboard',
                            loadChildren: () =>
                                import(
                                    './demo/components/call-dash-board/call.module'
                                ).then((m) => m.CallModule),
                        },
                    ],
                },
                {
                    path: 'auth',
                    loadChildren: () =>
                        import('./demo/components/auth/auth.module').then(
                            (m) => m.AuthModule
                        ),
                },
                {
                    path: 'landing',
                    loadChildren: () =>
                        import('./demo/components/landing/landing.module').then(
                            (m) => m.LandingModule
                        ),
                },
                { path: 'notfound', component: NotfoundComponent },
                { path: '**', redirectTo: '/notfound' },
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
